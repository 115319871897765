import { useEffect, useRef, useState } from 'react';
import PageTitle from '../components/PageTitle';
import './IntegrationPage.scss';
import api from '../common/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TextInput from '../components/inputs/TextInput';
import Button from '../components/inputs/Button';
import { useNotifications } from '../contexts/NotificationContext';

const vendorLogoMap = {
    "Sendcloud": require("../assets/sendcloud-logo.png"),
    "DHL": require("../assets/dhl-logo.png"),
    "HubSpot": require("../assets/hubspot-logo.png"),
    "Zenchef": require("../assets/zenchef-logo.png"),
};

const hubSpotRedirectHost = process.env.NODE_ENV === 'production' ? "https://api.qbud.ai" : "http://localhost:5000";
const vendorInfo = {
    Sendcloud: {
        description: "Sendcloud is a service for generating shipping labels for many shipping partners.",
        href: "https://sendcloud.com",
    },
    DHL: {
        description: "DHL is a shipping company that operates worldwide.",
        href: "https://dhl.com",
    },
    HubSpot: {
        description: "HubSpot offers tools for managing sales and marketing processes.",
        href: "https://hubspot.com",
        OAuthURL: `https://app-eu1.hubspot.com/oauth/authorize?client_id=957a3fd8-5fbd-43bb-a12a-02ebb38eb556&redirect_uri=${hubSpotRedirectHost}/api/v1/user/integrations/hubspot&scope=tickets%20crm.objects.contacts.write%20e-commerce%20oauth%20crm.objects.companies.write%20crm.objects.deals.write`
    },
    Zenchef: {
        description: "Zenchef is a reservation platform for restaurants.",
        href: "https://zenchef.com",
    }
}

function IntegrationCreds({userIntegrationId, integrationId, type, publicKey, vendor, creds, ...props}) {

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const { addNotification } = useNotifications();

    const checkEnableSubmit = () => {

        let enableSubmit = true;
        const formData = new FormData(formRef.current);
        for (const field of formData.keys()) {
            if (field === "integration_id") continue;
            const value = formData.get(field);
            if (!value || /^•+$/.test(field)) {
                enableSubmit = false;
                break;
            }
        }
        
        setSubmitDisabled(!enableSubmit);

    }

    const handleSubmit = (e) => {

        e.preventDefault();

        const formData = new FormData(formRef.current);
        const body = {};
        for (const field of formData.keys()) {
            body[field] = formData.get(field);
        }

        setLoading(true);

        const apiAction = userIntegrationId ? api.patch : api.post;
        const apiUrl = userIntegrationId ? `/user/integrations/${userIntegrationId}` : "/user/integrations";
        apiAction(apiUrl, body).then(() => {
            navigate('/integrations');
            addNotification(`Updated integration for ${vendor}.`);
        }).catch(() => {}).finally(() => setLoading(false));

    }

    let fields = <></>;
    if (type === "Basic") {

        fields = 
            <>
                <TextInput
                    id="integration-creds-public-key"
                    name="public_key"
                    placeholder="Public key / Client ID"
                    autoComplete="off"
                    example={`Issued by ${vendor}`}
                    inputCallback={checkEnableSubmit}
                    defaultValueControlled={publicKey}
                    />

                <TextInput 
                    id="integration-creds-private-key"
                    name="private_key"
                    placeholder="Private key / Client secret"
                    type="text"
                    autoComplete="off"
                    example={`Issued by ${vendor}`}
                    inputCallback={checkEnableSubmit}
                    defaultValueControlled={publicKey && "•••••••••••••"}
                    />
            </>
        ;

    } else if (type === "ApiKey") {

        fields = 
            <>
                <TextInput 
                    id="integration-creds-private-key"
                    name="private_key"
                    placeholder="API key"
                    type="text"
                    autoComplete="off"
                    example={`Issued by ${vendor}`}
                    inputCallback={checkEnableSubmit}
                    defaultValueControlled={userIntegrationId && "•••••••••••••"}
                    />
            </>
        ;

    } else if (type === "OAuth") {

        fields = 
            <>

                <Button
                    text={userIntegrationId ? "Re-authorize qBud" : "Authorize qBud"}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg>}
                    to={vendorInfo[vendor].OAuthURL}
                    cta
                    />

            </>

    }

    return (

        <form 
            id="integration-creds"
            autoComplete="off"
            onSubmit={handleSubmit}
            ref={formRef}
            >

            {fields}

            <input type="hidden" name="integration_id" value={integrationId} />

            {userIntegrationId &&
                <p id="integration-added">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                    qBud has been authorized for this app. Only update credentials if those issued by your vendor have changed.
                </p>
            }

            {["ApiKey", "Basic"].includes(type) &&
                <Button
                    text="Store"
                    cta="submit"
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-5l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z"/></svg>}
                    disabled={submitDisabled}
                    submit
                    />
            }

        </form>

    )

}

export default function IntegrationPage() {
    
    const { integration_id } = useParams();

    const [integration, setIntegration] = useState({});
    const [userIntegration, setUserIntegration] = useState({});

    useEffect(() => {

        api.get(`/integrations/${integration_id}`).then(response => {
            setIntegration(response.data.data.integration);
        });

        api.get(`/user/integrations?integration_id=${integration_id}`).then(response => {
            const newUserIntegrations = response.data.data.user_integrations;
            if (newUserIntegrations.length) setUserIntegration(newUserIntegrations[0]);
        })

    }, []);
    

    return (

        <div 
            id="integration"
            >

            <PageTitle 
                title={integration.name}
                breadcrumb={[
                    {href: '/integrations', text: 'Integrations'}
                 ]}
                />

            <div className="container">

                <div
                    id="integration-settings" 
                    className="block">

                    <h2>{integration.name}</h2>
                    <p>{integration.description}</p>

                    <IntegrationCreds 
                        userIntegrationId={userIntegration.id}
                        integrationId={integration.id}
                        publicKey={userIntegration.public_key}
                        type={integration.auth_method}
                        vendor={integration.vendor}
                        />

                </div>

                <div 
                    id="integration-vendor"
                    className="block">
                    <img src={integration ? vendorLogoMap[integration.vendor] : ""} />
                    <h2>Vendor Info</h2>
                    <p>{integration.vendor && vendorInfo[integration.vendor].description}</p>
                    <Link 
                        target="_blank"
                        to={integration.vendor && vendorInfo[integration.vendor].href}>View vendor<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg></Link>
                </div>
                
            </div>

        </div>

    );

}