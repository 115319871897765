import { useLocation, useNavigate } from 'react-router';
import PageTitle from '../components/PageTitle';
import './AccountPage.scss';
import { useEffect, useRef, useState } from 'react';
import TextInput from '../components/inputs/TextInput';
import Button from '../components/inputs/Button';


import { useNotifications } from '../contexts/NotificationContext';
import api from '../common/api';
import { Link, useSearchParams } from 'react-router-dom';
import AreYouSure from '../components/AreYouSure';
import BigCheckbox from '../components/inputs/BigCheckbox';
import DarkModeCheckbox from '../components/inputs/DarkModeCheckbox';


function AccountProfileForm(props) {

    return (
        <>

            <h2>Personal data.</h2>
            <p>Change your first and last name here.</p>

            <form
                onSubmit={props.handleSubmit}
                >
            
                <TextInput 
                    name="first_name"
                    type="text"
                    placeholder="First name"
                    example="Your first name"
                    maxLength={40}
                    defaultValueControlled={props.user.first_name}
                    validate
                />

                <TextInput 
                    name="last_name"
                    type="text"
                    placeholder="Last name"
                    example="Your last name"
                    maxLength={40}
                    defaultValueControlled={props.user.last_name}
                    validate
                />

                <Button 
                    loading={props.submissionLoading}
                    text="Save"
                    submit
                />
            
            </form>

        </>

    );

}


function AccountSecurityForm(props) {

    return (
        <>

            <h2>Security.</h2>
            <p>Update your password here.</p>

            <form
                onSubmit={props.handleSubmit}
                >
            
                <TextInput 
                    name="password"
                    type="password"
                    placeholder="Old password"
                    example="•••••••••"
                    maxLength={40}
                    error={props.errors.password}
                    validate
                />

                <TextInput 
                    name="new_password"
                    type="password"
                    placeholder="New password"
                    example="•••••••••"
                    error={props.errors.new_password}
                    newPassword
                    validate
                />

                <Button 
                    loading={props.submissionLoading}
                    text="Save"
                    submit
                />

            </form>

        </>
    );

}

function APIKeyForm(props) {

    // form controls
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [exposedSecret, setExposedSecret] = useState(null);

    const submitTokenRequest = (e) => {

        e.preventDefault();

        setLoading(true);
        const url = '/user/auth_token';
        api.get(url).then(response => {
            setClientId(response.data.data.user.client_id);
            setExposedSecret(response.data.data.user.client_secret);
            setLoading(false);
        });
    
    }
    
    return (
        <>

            <h2>API credentials.</h2>
            <p>Use this information for connecting to the qBud API.</p>

            <form
                onSubmit={submitTokenRequest}
                >
            
                <TextInput 
                    name="client-id"
                    type="text"
                    placeholder="Client ID"
                    defaultValueControlled={props.user.client_id || clientId}
                    readOnly
                />
                
                <TextInput 
                    name="client-secret"
                    type="text"
                    placeholder="Client secret"
                    defaultValueControlled={exposedSecret ? exposedSecret : (props.user.client_id ? "qapi•••••••••" : "")}
                    readOnly
                />

                {exposedSecret &&
                    <span
                        className="exposed-message"
                        >
                        Copy the secret. It's only available now.
                    </span>
                }

                <Button 
                    loading={loading}
                    text="Generate"
                    submit
                />

            </form>

        </>
    );

}

function MFAForm(props) {

    // form controls
    const [loading, setLoading] = useState(false);

    return (
        <>

            <h2>Multi-factor authentication.</h2>
            <p>Enhance the security of your account.</p>

            <form
                onSubmit={props.handleSubmit}
                >

                <BigCheckbox 
                    name="mfa_enabled"
                    title="Multi-factor authentication"
                    text="Upon a login attempt, receive an email with an additional code to enter. Improves account security."
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10-4c0-2.206 1.795-4 4-4s4 1.794 4 4v4h-8v-4zm11 16h-14v-10h14v10zm-7.737-2l-2.843-2.756 1.173-1.173 1.67 1.583 3.563-3.654 1.174 1.173-4.737 4.827z"/></svg>}
                    defaultChecked={props.user.mfa_enabled}
                    />

                <Button 
                    loading={props.submissionLoading}
                    text="Save"
                    submit
                />
                    
            </form>

        </>
    );

}

function AppearanceForm(props) {

    return (
        <>
            <h2>Appearance.</h2>
            <p>Customize how the qBud webapp looks.</p>
            <DarkModeCheckbox />
        </>
    )

}

function AccountPayments() {

    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [payments, setPayments] = useState([]);

    useEffect(() => {

        setPaymentsLoading(true);
        api.get("/user/payments").then(response => {
            setPayments(response.data.data.user_payments);
            setPaymentsLoading(false);
        });

    }, []);

    return (
        <>

            <h2>Overview</h2>

            <ul 
                id="payments"
                className="block"
                >
                
                <li><div>Date</div><div>Status</div><div>Amount</div><div></div></li>

                {!paymentsLoading && payments.length === 0 && 
                    <li><div>There are no payments yet.</div></li>
                }

                {payments.length > 0 && payments.map((payment, key) => {
                    return (
                        <li
                            key={key}
                            >
                            <div>{new Date(payment.created_at).toLocaleDateString()}</div>
                            <div>Succeeded</div>
                            <div>{(payment.amount / 100).toLocaleString(undefined, { style: 'currency', currency: payment.currency })}</div>
                            <div></div>
                        </li>
                    )
                })

                }

            </ul>

        </>
    );

}

function AccountSubscriptions() {

    const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [areYouSureShow, setAreYouSureShow] = useState(false);
    const [areYouSureUrl, setAreYouSureUrl] = useState("");

    const { addNotification } = useNotifications();

    useEffect(() => {

        setSubscriptionsLoading(true);
        api.get("/user/subscriptions").then(response => {
            setSubscriptions(response.data.data.subscriptions);
            console.log(response);
            setSubscriptionsLoading(false);
        });

    }, []);

    return (
        <>

            <h2>Overview</h2>

            <ul 
                id="subscriptions"
                className="block"
                >
                
                <li><div>Items</div><div>Creation Date</div><div>Renewal Date</div><div className="thin">Status</div><div className="thin">Amount</div><div className="thin">Frequency</div><div></div></li>

                {!subscriptionsLoading && subscriptions.length === 0 && 
                    <li><div>There are no subscriptions yet.</div></li>
                }

                {subscriptions.length > 0 && subscriptions.map((subscription, key) => {

                    const displayStatus = subscription.status.charAt(0).toUpperCase() + subscription.status.substring(1);
                    const displayFrequency = subscription.invoice_type.charAt(0).toUpperCase() + subscription.invoice_type.substring(1);
                    const containsPro = subscription.plans.filter(plan => plan.name === "Pro").length > 0;
                    const cancelled = subscription.status === "cancelled";

                    const localeDateOptions = {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    };

                    return (
                        <li
                            key={key}
                            >
                            <div>
                                <ul>
                                    {subscription.plans.map((plan, key) => {
                                        return (
                                            <li 
                                                key={key}
                                                className={plan.category}
                                                >
                                                {plan.quantity > 1 && <span className="quantity">{plan.quantity}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>}
                                                {plan.name}
                                                {plan.category === 'addon' && <span className="addon">+</span>}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div>{new Date(subscription.created_at).toLocaleDateString(undefined, localeDateOptions)}</div>
                            <div>{new Date(subscription.expires_at).toLocaleDateString(undefined, localeDateOptions)}{cancelled ? <><br />(Expiry date)</> : ""}</div>
                            <div className="thin">
                                <span className={"status" + (cancelled ? " cancelled" : "")}>{displayStatus}</span>
                            </div>
                            <div className="thin">{((subscription.amount ? subscription.amount : 0) / 100).toLocaleString(undefined, { style: 'currency', currency: subscription.currency ? subscription.currency : "eur" })}</div>
                            <div className="thin">{displayFrequency}ly</div>
                            <div>                                
                                <span
                                    className={cancelled ? "cancelled": ""}
                                    onClick={() => {
                                        if (cancelled) return;
                                        setAreYouSureShow(true);
                                        setAreYouSureUrl(`/user/subscriptions/${subscription.id}/:cancel`);
                                    }}
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
                                </span>
                            </div>
                        </li>
                    )
                })

                }

            </ul>

            <AreYouSure 
                isOpen={areYouSureShow}
                setIsOpen={setAreYouSureShow}
                url={areYouSureUrl}
                children={
                    <>
                        <h3>Are you sure?</h3>
                        <p>You are about to cancel a subscription.<br /><br />Please note that this cannot be undone. If you wish to upgrade your subscription, you should do so <Link to="/plans">here</Link>.</p>
                    </>
                }
                verySure={true}
                successCallback={() => { addNotification("Successfully cancelled subscription."); }}
            />

        </>
    );

}


export default function AccountPage() {

    // state vars
    const [submissionLoading, setSubmissionLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});

    // notifications
    const { addNotification } = useNotifications();

    // redirect to profile
    const navigate = useNavigate();
    const location = useLocation();
    const [search, setSearch] = useSearchParams();
    useEffect(() => {
        if (location.pathname === '/account') {
            navigate('/account/general');
        }
    }, [location.pathname]);

    // fetch user data
    useEffect(() => {
        setLoading(true);
        api.get("/user").then(response => {
            setUser(response.data.data.user)
            setLoading(false);
        });
    }, []);

    const handleSubmit = (e) => {

        e.preventDefault();
        setErrors({});
        const newErrors = {};
        let inputs = e.target.getElementsByTagName("input");
        for (const input of inputs) {
            if (!input.classList.contains("validated") && ["text", "password"].includes(input.getAttribute('type'))) {   
                newErrors[input.getAttribute("name")] = 'Vul dit veld correct in.';
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setTimeout(() => {
                setErrors({...newErrors})
            }, 100);
            return;
        }

        const formData = new FormData(e.target);
        setSubmissionLoading(true);

        const body  = {}
        formData.forEach((value, key) => {body[key] = value});
        
        // no keys is indicative of mfa preferences being submitted and them being unchecked
        if (Object.keys(body).length === 0) body.mfa_enabled = "";

        api.patch("/user", body).then(() => {
            addNotification('Je gegevens zijn opgeslagen.');
        }).catch(error => {
            if (Object.keys(error.data).includes("message") && error.data.message === "Invalid password.") {
                setErrors({new_password: "Het oude wachtwoord is niet correct."});
            }
        }).finally(() => {
            setSubmissionLoading(false);  
        });


    }

    return (
        <div 
            id="account"
            >

            <PageTitle 
                title="Account"
                links={[
                    {text: 'General', to: '/account/general', icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 21.193l-.003.807h-19.993l-.004-.833c-.009-2.224.088-3.495 2.647-4.086 2.805-.647 5.573-1.227 4.242-3.682-3.943-7.275-1.123-11.399 3.111-11.399 4.153 0 7.043 3.971 3.11 11.398-1.292 2.44 1.375 3.02 4.242 3.682 2.57.594 2.657 1.873 2.648 4.113zm4-17.193h-7v2h7v-2zm0 4h-7v2h7v-2zm0 4h-7v2h7v-2z"/></svg>},
                    {text: 'Subscriptions', to: '/account/subscriptions', icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 5h-24v9h24v-9zm-13-1h-9v-2.5c0-.829.672-1.5 1.5-1.5h6c.828 0 1.5.671 1.5 1.5v2.5zm11 0h-9v-2.5c0-.829.672-1.5 1.5-1.5h6c.828 0 1.5.671 1.5 1.5v2.5zm2 11h-24v9h24v-9z"/></svg>},
                    {text: 'Payments', to: '/account/payments', icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 4h-20c-1.104 0-2 .896-2 2v12c0 1.104.896 2 2 2h20c1.104 0 2-.896 2-2v-12c0-1.104-.896-2-2-2zm-19 5.78c0-.431.349-.78.78-.78h.428v1.125h-1.208v-.345zm0 .764h1.208v.968h-1.208v-.968zm0 1.388h1.208v1.068h-.428c-.431 0-.78-.349-.78-.78v-.288zm3 5.068h-3v-1h3v1zm1-4.78c0 .431-.349.78-.78.78h-.429v-1.068h1.209v.288zm0-.708h-1.209v-.968h1.209v.968zm0-1.387h-1.629v2.875h-.743v-4h1.592c.431 0 .78.349.78.78v.345zm4 6.875h-3v-1h3v1zm1-6.5c0-1.381 1.119-2.5 2.5-2.5.484 0 .937.138 1.32.377-.531.552-.857 1.3-.857 2.123 0 .824.327 1.571.857 2.123-.383.239-.836.377-1.32.377-1.381 0-2.5-1.119-2.5-2.5zm4 6.5h-3v-1h3v1zm5 0h-3v-1h3v1zm-2.5-4c-1.38 0-2.5-1.119-2.5-2.5s1.12-2.5 2.5-2.5c1.381 0 2.5 1.119 2.5 2.5s-1.119 2.5-2.5 2.5z"/></svg>},
                ]}
                />
            
            <div
                id="account-content"
                className={"container"}
                >

                {location.pathname === '/account/general' &&
                    <>
                        <div id="account-stats">
                            <div className="block">
                                <span>{Intl.NumberFormat(navigator.language || navigator.languages[0]).format(user.credits)}</span>
                                <h2>Qredits remaining</h2>
                            </div>
                            <div className="block">
                                <span>{Intl.NumberFormat(navigator.language || navigator.languages[0]).format(user.max_assistants)}</span>
                                <h2>Max assistants</h2>
                            </div>
                            <div className="block">
                                <span>{Intl.NumberFormat(navigator.language || navigator.languages[0]).format(user.max_indices)}</span>
                                <h2>Max indices</h2>
                            </div>
                        </div>
                        <div id="account-general">
                            <div className="block"><AccountProfileForm handleSubmit={handleSubmit} errors={errors} submissionLoading={submissionLoading} user={user} /></div>
                            <div className="block"><AccountSecurityForm handleSubmit={handleSubmit} errors={errors} submissionLoading={submissionLoading} /></div>
                            <div className="block"><APIKeyForm user={user} /></div>
                            <div className="block"><MFAForm handleSubmit={handleSubmit} submissionLoading={submissionLoading} user={user} /></div>
                            <div className="block"><AppearanceForm /></div>
                            <Button 
                                text="Log out"
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-2 10v-.083c-1.178.685-2.542 1.083-4 1.083-4.411 0-8-3.589-8-8s3.589-8 8-8c1.458 0 2.822.398 4 1.083v-2.245c-1.226-.536-2.577-.838-4-.838-5.522 0-10 4.477-10 10s4.478 10 10 10c1.423 0 2.774-.302 4-.838v-2.162z"/></svg>}
                                to="/logout"
                                />
                        </div>
                    </>
                }

                {location.pathname === '/account/payments' && <AccountPayments />}

                {location.pathname === '/account/subscriptions' && <AccountSubscriptions />}

            </div>

        </div>
    )
    
}