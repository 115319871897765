import { useEffect, useState } from "react";
import TextInput from "../inputs/TextInput";

import './PromptBuilder.scss';
import Button from "../inputs/Button";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../common/api";
import { SingleSelect } from "../inputs/Select";
import { useNotifications } from "../../contexts/NotificationContext";

export default function PromptBuilder({defaultPrompts, loading}) {

   const [prompts, setPrompts] = useState({"text": [], "url": [], "file": []});
   const [promptDeleted, setPromptDeleted] = useState([]);
   const [scrapeLoading, setScrapeLoading] = useState(false);
   const [simplificationLoading, setSimplificationLoading] = useState(false);
   const [availableFiles, setAvailableFiles] = useState([]);

   const { addNotification } = useNotifications();

   const { user } = useAuth();

   const addPrompt = (type) => {

      const tempId = Math.random().toString(36).substring(2, 12);
      setPrompts(prevPrompts => ({
         ...prevPrompts, 
         [type]: [
            ...prevPrompts[type], 
            {title: "", text: "", open: true, url: null, url_type: "static", updated: true, temp_id: tempId, sorting_order: prevPrompts[type].length}
         ]
      }));

   }

   const swapPrompts = (type, key, newKey) => {

      let newPromptsForType = [...prompts[type]];
      [newPromptsForType[key], newPromptsForType[newKey]] = [newPromptsForType[newKey], newPromptsForType[key]]
      newPromptsForType = newPromptsForType.map((prompt, key) => ({...prompt, sorting_order: key, updated: true}));
      setPrompts(prevPrompt => ({
         ...prevPrompt,
         [type]: newPromptsForType
      }));
   }

   const simplifyText = (type, key) => {

      const text = prompts[type][key].text;
      if (text.length > 5000) addNotification("Cannot simplify text longer than 5000 characters.");
      if (!text || text.length < 200 || text.length > 5000) return;

      setSimplificationLoading(true);
      addNotification("Simplification started.");
      api.post("/utils/simplifications", {prompt: text})
            .then(response => {
               const simplification = response.data.data.utils.simplification;
               if (simplification.length < text.length) {
                  addNotification(`Reduced component length by ${text.length - simplification.length} characters.`)
                  setPrompts(prevPrompt => ({
                     ...prevPrompt,
                     [type]: prevPrompt[type].map((prompt, promptKey) => key === promptKey ? { ...prompt, updated: true, simplified: true, text: simplification } : prompt)
                  }));
               } else {
                  addNotification("Further simplification is not possible.");
                  setPrompts(prevPrompt => ({
                     ...prevPrompt,
                     [type]: prevPrompt[type].map((prompt, promptKey) => key === promptKey ? { ...prompt, simplified: true } : prompt)
                  }));
               };
            })
            .catch(() => {})
            .finally(() => setSimplificationLoading(false));

   }

   const updatePromptValue = (type, key, field, value) => {

      setPrompts(prevPrompt => ({
         ...prevPrompt,
         [type]: prevPrompt[type].map((item, itemKey) => key === itemKey ? { ...item, [field]: value, updated: true } : item)
      }));

   }

   const deletePromptComponent = (type, key) => {

      if (prompts[type][key].id) {
         setPromptDeleted([...promptDeleted, prompts[type][key].id]);
      }

      let newPromptsForType = prompts[type].filter((_, itemKey) => key !== itemKey);
      newPromptsForType = newPromptsForType.map((prompt, key) => ({...prompt, sorting_order: key, updated: true}));
      setPrompts(prevPrompt => ({
         ...prevPrompt,
         [type]: newPromptsForType
      }));

   }

   const scrapeUrl = (key) => {

      const url = prompts.url[key].url;
      if (!url || url.length < 3) return;

      setScrapeLoading(true);
      addNotification("Scrape started.");
      api.post("/utils/scrapes", {url: url})
         .then(response => {
            addNotification(`Successfully scraped ${url}.`);
            const scrapeOutput = response.data.data.utils.scrape;
            setPrompts(prevPrompt => ({
               ...prevPrompt,
               url: prevPrompt.url.map((prompt, promptKey) => key === promptKey ? { ...prompt, updated: true, open: true, text: scrapeOutput } : prompt)
            }));
         })
         .catch(response => {
            if (response.status === 503) {
               addNotification("The URL refused connection. This may be caused by anti-scrape measures.", true);
            }
         })
         .finally(() => setScrapeLoading(false));

   }

   const getTextFromFile = (fileId, key) => {

      if (!fileId) return;
      api.get(`/files/${fileId}/text`)
         .then(response => {
            
            const textOutput = response.data.data.file.text;
            if (textOutput.length === 0) {
               addNotification("No text was found.");
            }

            setPrompts(prevPrompt => ({
               ...prevPrompt,
               file: prevPrompt.file.map((prompt, promptKey) => key === promptKey ? { ...prompt, updated: true, open: true, text: textOutput } : prompt)
            }));
            if (textOutput.length === 500) {
               addNotification("Text was truncated to 5000 characters.");
            }
            addNotification("File text retrieved.");
         })

   }

   useEffect(() => {
      if (defaultPrompts) {
         const initialPrompts = {...defaultPrompts};
         for (const promptType of ["text", "url", "file"]) {
            if (initialPrompts[promptType]) {
               initialPrompts[promptType] = initialPrompts[promptType].map((prompt) => ({...prompt, open: false}));
            } else {
               initialPrompts[promptType] = [];
            }
         }
         setPrompts(initialPrompts);
      }
   }, [defaultPrompts]);

   useEffect(() => {

      api.get("/files").then(response => {
         const files = response.data.data.files;
         const newAvailableFiles = [];
         for (const file of files) {
            newAvailableFiles.push({value: file.id, showValue: file.remote_name.split("/").pop()})
         }
         setAvailableFiles(newAvailableFiles);
      })

   }, []);

   const generateComponents = (type) => {

      return (
         
         <>

            {prompts[type].map((text, key) => {

               return (
                  <div 
                     className={"prompt-text" + (text.open ? " open" : "")}
                     key={text.id || text.temp_id}>
                     
                     <div className="prompt-title">

                        <div className="prompt-title-inputs">
                           
                           <TextInput
                              example="About page of qBud"
                              placeholder="Title"
                              info="Describe the type of information to your assistant."
                              defaultValueControlled={text.title}
                              inputCallback={(_, newValue) => updatePromptValue(type, key, "title", newValue)}
                              noWhiteBackground
                              maxLength={100}
                              />

                           {type === "url" &&

                              <>

                                 <div className="text-input-scrape">

                                    <TextInput 
                                       id="assistant-instruction-scrape"
                                       example="qbud.ai/about"
                                       placeholder="URL"
                                       maxLength="200"
                                       autoComplete="off"
                                       info="Scrape information from this URL."
                                       inputCallback={(_, newValue) => {
                                          updatePromptValue(type, key, "url", newValue)
                                       }}
                                       defaultValueControlled={text.url}
                                       readOnly={scrapeLoading}
                                       onKeyDown={user.role === "admin" ? (e) => {
                                          if (e.key === "Enter") {
                                             e.preventDefault();
                                             scrapeUrl(key);
                                          }
                                       } : null}
                                       noWhiteBackground
                                       />

                                    {user.role === "admin" &&
                                       <Button
                                          onClick={() => {
                                             scrapeUrl(key);
                                          }}
                                          icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 12l-20 12 5-12-5-12z"/></svg>}
                                          loading={scrapeLoading}
                                          />
                                    }

                                 </div>

                                 <SingleSelect 
                                    className="text-input-scrape-type"
                                    placeholder="Scrape type"
                                    choices={[
                                       {value: "static", showValue: "Static"},
                                       {value: "sync", showValue: "Sync"},
                                    ]}
                                    defaultValueControlled={text && text.url_type}
                                    changeCallback={(newValue) => updatePromptValue(type, key, "url_type", newValue)}
                                    info="Determines if scrape results are refreshed every 24H. 'Static' is recommended, unless the source webpage is highly dynamic."
                                    noWhiteBackground
                                    />

                              </>

                           }

                           {type === "file" &&
                           
                              <SingleSelect 
                                 className="text-input-file"
                                 placeholder="File"
                                 choices={availableFiles}
                                 defaultValueControlled={text && text.file_id}
                                 changeCallback={(newValue) => {
                                    updatePromptValue(type, key, "file_id", newValue)
                                    getTextFromFile(newValue, key);
                                 }}
                                 info="Determines if scrape results are refreshed every 24H. 'Static' is recommended, unless the source webpage is highly dynamic."
                                 noWhiteBackground
                                 />

                           }

                        </div>

                        <span 
                           className="prompt-open-toggle"
                           onClick={() => setPrompts(prevPrompt => ({
                              ...prevPrompt,
                              [type]: prevPrompt[type].map((item, itemKey) => key === itemKey ? { ...item, open: !prevPrompt[type][key].open } : item)
                           }))}
                           >
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
                        </span>
                     </div>

                     <TextInput
                        example="qBud is a company that provides AI assistants."
                        placeholder="Value"
                        className={(text.open ? "open" : "") + (text.text && text.text.length > 200 && !text.simplified ? " simplify" : "")}
                        defaultValueControlled={text.text}
                        inputCallback={(_, newValue) => updatePromptValue(type, key, "text", newValue)}
                        maxLength={5000}
                        rows={8}
                        readOnly={simplificationLoading}
                        noWhiteBackground
                        multiline
                        />

                     {user.role === "admin" && text.text && text.text.length > 200 && !text.simplified &&
                        <div className="prompt-simplification">
                           <Button
                              onClick={() => simplifyText(type, key)}
                              icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"/></svg>}
                              text="Simplify"
                              loading={simplificationLoading}
                              />
                        </div>
                     }

                     <p className="prompt-buttons">
                        <div>
                           <span
                              className={key === 0 ? "disabled": ""}
                              onClick={() => swapPrompts(type, key, key - 1)}
                              >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>
                              Move up
                           </span>
                           <span
                              className={key === prompts[type].length - 1 ? "disabled": ""}
                              onClick={() => swapPrompts(type, key, key + 1)} 
                              >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>
                              Move down
                           </span>
                        </div>
                        <span onClick={() => deletePromptComponent(type, key)}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 6l-3 18h-12l-3-18h2.028l2.666 16h8.611l2.666-16h2.029zm-4.711-4c-.9 0-1.631-1.099-1.631-2h-5.316c0 .901-.73 2-1.631 2h-5.711v2h20v-2h-5.711z"/></svg>
                           Delete
                        </span>
                     </p>

                  </div>
               )

            })}

            <Button 
               text={`Add ${type}`}
               disabled={loading || prompts[type].length >= 10 || prompts[type].length > 0 && !prompts[type][prompts[type].length - 1].text}
               onClick={() => addPrompt(type)}
               icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>}
            />
            
         </>

      );

   }

   return (
      <div id="prompt-builder">

         <h2>Text components.</h2>
         <p>These are static text that you can tune to your liking.</p>
         
         {generateComponents("text")}

         <h2>Webpage components.</h2>
         <p>Generated from an external URL. Optionally, these are synced automatically.</p>

         {generateComponents("url")}

         <h2>Document components.</h2>
         <p>Generated from a qBud File.</p>

         {generateComponents("file")}

         <input type="hidden" name="prompts" value={JSON.stringify({
            text: prompts.text.filter(prompt => prompt.updated && (prompt.text || prompt.title)).map(({updated, temp_id, open, simplified, ...text}) => text),
            url: prompts.url.filter(prompt => prompt.updated && (prompt.text || prompt.title)).map(({updated, temp_id, open, simplified, ...text}) => text),
            file: prompts.file.filter(prompt => prompt.updated && (prompt.text || prompt.title)).map(({updated, temp_id, open, simplified, ...text}) => text),
         })} />
         <input type="hidden" name="prompts_delete" value={promptDeleted.join(",")} />

      </div>
   )

}