import { useEffect, useState } from 'react';
import PageTitle from '../components/PageTitle';
import './IntegrationsOverviewPage.scss';
import api from '../common/api';
import { Link, useSearchParams } from 'react-router-dom';
import { useNotifications } from '../contexts/NotificationContext';

const vendorLogoMap = {
    "Sendcloud": require("../assets/sendcloud-logo.png"),
    "DHL": require("../assets/dhl-logo.png"),
    "HubSpot": require("../assets/hubspot-logo.png"),
    "Zenchef": require("../assets/zenchef-logo.png")
};

const vendorSpelling = {
    "dhl": "DHL",
    "sendcloud": "Sendcloud",
    "hubspot": "HubSpot",
    "zenchef": "Zenchef"
}

export default function IntegrationsOverviewPage() {
    
    const [integrations, setIntegrations] = useState(null);
    const [userIntegrations, setUserIntegrations] = useState(null);
    const [statusIntegrations, setStatusIntegrations] = useState({"Live": [], "Available": []});

    const [search, setSearch] = useSearchParams();
    const { addNotification } = useNotifications();

    useEffect(() => {

        api.get("/user/integrations").then(response => {
            setUserIntegrations(response.data.data.user_integrations);
        });

        api.get("/integrations").then(response => {
            setIntegrations(response.data.data.integrations);
        })

        if (search.get("updated_vendor") && Object.keys(vendorSpelling).includes(search.get("updated_vendor"))) {
            addNotification(`Updated integration for ${vendorSpelling[search.get("updated_vendor")]}.`);
        }

    }, []);
    
    useEffect(() => {

        if (integrations && integrations.length && userIntegrations !== null) {
            setStatusIntegrations({
                "Live": integrations.filter(integration => userIntegrations.some(userIntegration => userIntegration.integration_id === integration.id)),
                "Available": integrations.filter(integration => !(userIntegrations.some(userIntegration => userIntegration.integration_id === integration.id)))
            })
        }

    }, [integrations, userIntegrations]);

    return (

        <div 
            id="integrations"
            >

            <PageTitle 
                title="Integrations"
                />

            <div className="container">

                {Object.keys(statusIntegrations).map((status, statusKey) => {

                    return (
                        <>
                            <h2>{status}</h2>
                            
                            {statusIntegrations[status].length === 0 &&
                                <p>There are no {status.toLowerCase()} integrations.</p>
                            }

                            {statusIntegrations[status].length > 0 && 

                                <>
                                    <p>{statusIntegrations[status].length} {status.toLowerCase()}</p>
                                    <div className="integrations-wrapper">

                                        {statusIntegrations[status].map(integration => {
                                            return (
                                                <Link 
                                                    to={`/integrations/${integration.id}`}
                                                    className="integration">
                                                    <div className="block">
                                                        <div className="text">
                                                            <h3>{integration.vendor}</h3>
                                                            <p>{integration.name}</p>
                                                        </div>
                                                        <img src={vendorLogoMap[integration.vendor]} />
                                                    </div>
                                                </Link>
                                            )
                                        })}
        
                                    </div>
                                </>

                            }

                        </>
                    )

                })}

            </div>

        </div>

    );

}